import * as routes from "Figured/Assets/Modules/routes";
import * as notify from "Figured/Assets/Modules/notify";
import http from "Figured/Assets/Modules/http";
import Base from "./Base.js";
import Vue from "vue";

/**
 * This is the base model class which abstracts and allows the following:
 *     - Create, Read, Update, Delete
 *     - Safe to modify attributes, can reset to 'reference' state.
 *     - State management: loading, saving, deleting.
 *     - Automatically add and remove from collections on save and delete.
 *     - Flexible, easy to extend and hook into for special cases.
 */
class Model extends Base {

    /**
     * Creates a new instance, called when using 'new'.
     */
    constructor(data, collection, options) {
        super();

        // Binds all methods of this instance to itself. This might not actually
        // be necessary for everything but allows for intuitive use of `this`
        // inside functions of this model.
        this.bindAll();

        // Defines an automatic model id that is something completely different
        // to the id of the model in the database. This is primarily to
        // distinguish between instances of the same model, or where two models
        // have the same data but aren't the same instance.
        Object.defineProperty(this, "__uid", {
            value:        Model.auto_id += 1,
            enumerable:   false,
            configurable: false,
            writable:     false,
        });

        Vue.set(this, "$collections",   {});        // Allows for auto add/remove on save/delete.
        Vue.set(this, "$reference",     {});        // Reference data, source of truth, 'saved'
        Vue.set(this, "$attributes",    {});        // Model data that can be modified safely.
        Vue.set(this, "$errors",        {});        // Error data, usually validation errors.
        Vue.set(this, "$listeners",     {});        // Event listeners.

        options = _.defaults(options, {
            deleting: false,
            loading:  true,
            saving:   false,
            fatal:    false,
        });

        //
        _.each(options, (value, option) => {
            Vue.set(this, option, value);
        });

        // Assign all given model data to the model's attributes and reference.
        this.assign(data);

        // Register the given collection (if any) to the model. This is so that
        // the model can be added to the collection automatically when it's
        // created on save, or removed when it's deleted on delete.
        this.registerCollection(collection);

        this.boot();
    }

    /**
     * @returns {*}
     */
    get attributes() {
        return this.$attributes;
    }

    /**
     * Called after construction.
     */
    boot() {

    }

    /**
     * This is a hook to convert plain object data into whatever model types it
     * needs to be. For example, a Parent has a Child attribute, which should be
     * a Child model but is currently just a plain object. This is where you would
     * convert that object into a Child model.
     */
    hydrate() {

    }

    /**
     * Emits an event by name. All listeners attached to this event will be called
     * with this model as the first argument and any args passed to this method.
     *
     * Listeners can return `false` to cut the process short.
     *
     * @return {boolean} whether or not all listeners were handled successfully.
     */
    emit(event, context = {}) {
        let listeners = _.get(this.$listeners, event, []);
        let completed = true;

        // Add defaults to the context.
        context = _.merge({}, context, {model: this});

        // Run through each listener. If any of them return false, stop the
        // iteration and mark that the event wasn't handled by all listeners.
        _.each(listeners, (listener) => {
            return (completed = listener(context)) === false;
        });

        return completed;
    }

    /**
     * Register an event listener.
     */
    on(event, listener) {
        this.$listeners[event] = _.get(this.$listeners, event, []);
        this.$listeners[event].push(listener);
    }

    /**
     * A convenience wrapper around the model's attributes that are saved
     * (persisted). This is similar to the @saved method, but instead of
     * accessing a single property it returns the whole saved object, so
     * that you can do something like model.$.attribute when you want to
     * display it somewhere.
     */
    get $() {
        return this.$reference;
    }

    /**
     * Assign all given model data to the model's attributes and reference.
     * This will also fill any gaps with the model's default values.
     */
    assign(data) {
        let defaults   = this.defaults();
        let attributes = _.defaultsDeep(data, defaults);

        // Set all attributes and sync reference with the attributes.
        this.set(attributes);
        this.hydrate();
        this.sync();
    }

    /**
     * Registers a collection on this model.
     */
    registerCollection(collection) {
        if (collection) {
            Vue.set(this.$collections, collection.__uid, collection);
        }
    }

    /**
     * Removes a registered collection from this model.
     */
    unregisterCollection(collection) {
        if (collection) {
            Vue.delete(this.$collections, collection.__uid);
        }
    }

    /**
     * Resets all attributes back to their defaults.
     */
    clear() {
        this.set(this.defaults());

        this.$errors = {};
    }

    /**
     * @returns {Model}
     */
    copy() {
        let attributes = {};

        // Clone all attributes and their descriptors.
        this._cloneInto(this.attributes, attributes);

        // Create a copy.
        let copy = new (this.constructor)(attributes);

        // Register all existing collections on the copy.
        _.each(this.$collections, (collection) => {
            copy.registerCollection(collection);
        });

        return copy;
    }

    /**
     * This method recursively copies data from one object or array to another,
     * in such a way that it honours Vue's reactivity by using Vue.set
     *
     * @param source
     * @param target
     */
    _cloneInto(source, target) {
        _.each(source, (value, key) => {

            // Replace the existing array (if any) with a blank one, and copy
            // the new values into it recursively.
            if (_.isArray(value)) {
                Vue.set(target, key, []);
                this._cloneInto(value, target[key]);

            // Replace the existing object (if any) with a blank one, and copy
            // the new values into it recursively.
            } else if (_.isPlainObject(value)) {
                Vue.set(target, key, {});
                this._cloneInto(value, target[key]);

            // Honour an object's "copy" function, such as Model and Collection
            } else if (_.isObject(value) && _.isFunction(value.copy)) {
                Vue.set(target, key, value.copy());

            // Fall back to a standard deep clone.
            } else {
                Vue.set(target, key, _.cloneDeep(value));
            }
        });
    }

    /**
     * Resets all attributes back to their reference values (source of truth).
     * A good use case for this is when form fields are bound directly to the
     * model's attributes. Changing values in the form fields will change the
     * attributes on the model. On cancel, you can revert the model back to
     * its saved, original state using reset().
     */
    reset() {
        this._cloneInto(this.$reference, this.$attributes);

        this.$errors = {};
    }

    /**
     * Sync the current attributes to the reference attributes. This is usually
     * only called on save or update. We have to clone the values otherwise we
     * end up with references to the same object in both attribute sets.
     */
    sync() {
        this._cloneInto(this.$attributes, this.$reference);
    }

    /**
     * A convenience wrapper around the model's attributes to allow access with
     * a fallback like lodash's _.get, but directly on the model instead.
     */
    get(attribute, fallback) {
        return _.get(this.$attributes, attribute, fallback);
    }

    /**
     * Similar to @get, but accesses the reference attributes instead. This is
     * useful in cases where you want to display a value but also edit it. For
     * example, a modal with a title based on a model field, but you're also
     * editing that field. You don't want the title to change as you edit the
     * field, so render the title as @saved instead of the current attribute.
     */
    saved(attribute, fallback) {
        return _.get(this.$reference, attribute, fallback);
    }

    /**
     * This is used internally to set an attribute's value and set up that you
     * can access it directly on the model but also update the model's internal
     * attributes array.
     */
    set(attribute, value) {

        // Allow batch set of multiple attributes at once, ie. @set({...})
        if (_.isPlainObject(attribute)) {
            _.each(attribute, (value, key) => {
                this.set(key, value);
            });

            return;
        }

        // Only register the pass-through property if it hasn't been set up
        // already. If it already exists on the instance, we know it has been.
        if ( ! _.has(this.$attributes, attribute)) {

            // Protect against unwillingly using an attribute name that already
            // exists as an internal property or method name.
            if (_.includes(Object.getOwnPropertyNames(this), attribute)) {
                throw ReferenceError(`Can't use '${attribute}' as an attribute name.`);
            }

            // Create dynamic accessors and mutators so that we can update the
            // model directly while also keeping the model attributes in sync.
            let get = ()      => { return this.get(attribute); };
            let set = (value) => { return this.set(attribute, value);  };

            Object.defineProperty(this, attribute, { get, set });

            Vue.set(this.$attributes, attribute, value);

        // Attribute already exists, so we can update the model's attribute
        // and emit a change event if the value has changed.
        } else {
            let previous = this.get(attribute);

            // Set the attribute's new value.
            Vue.set(this.$attributes, attribute, value);

            // Emit an event to broadcast that a value has been set.
            this.emit("set", {attribute, value});

            // Only emit `change` if the value has changed.
            if ( ! _.isEqual(previous, value)) {
                this.emit("change", {attribute, previous, value});
            }
        }
    }

    /**
     * Returns an empty representation of this model.
     */
    defaults() {
        return {};
    }

    /**
     * Returns a route configuration in the form {key: name}, where key may be
     * 'save', 'fetch', 'delete' or any other custom key, and the name is what
     * will be passed to router to generate the URL. See @getRoute
     */
    routes() {
        return {};
    }

    /**
     * Creates a route using this model's configured route names and the
     * utility package's route generator.
     */
    getRoute(key, parameters) {
        let name = _.get(this.routes(), key);

        // Check that this model supports the requested route key.
        if (!name) {
            throw `Route key '${key}' not configured`;
        }

        return routes.get(name, _.defaultsDeep({}, this.getDefaultParameters(), parameters));
    }

    /**
     * Converts this model to a JSON string.
     */
    toJSON() {
        return this.$attributes;
    }

    /**
     * Updates the model data with data returned from the server.
     */
    updateAfterSave(data) {

        // No content means we don't want to update the model at all.
        if (_.isEmpty(data)) {
            this.sync();

        // A plain object implies that we want to replace the model data.
        } else if (_.isPlainObject(data)) {
            this.assign(data);

        // Check if only an integer was returned and assume it is the model's ID.
        } else {
            let id = _.toInteger(data);

            if (id > 0) {
                this.set(this.getIdentifier(), id);

            // Unexpected response.
            } else {
                throw "Empty, object, or integer ID expected in save response";
            }
        }
    }

    /**
     * Called when a save request was successful.
     */
    onSave(data, created) {
        this.updateAfterSave(data);

        // Add to all registered collections if this is a newly created model.
        if (created) {
            this.addToAllCollections();
        }
    }

    /**
     * Adds this model to all registered collections.
     */
    addToAllCollections() {
        _.each(this.$collections, (collection, id) => {
            collection.add(this);
        });
    }

    /**
     * Removes this model from all registered collections.
     */
    removeFromAllCollections() {
        _.each(this.$collections, (collection, id) => {
            collection.remove(this);
        });
    }

    /**
     * Returns the attributes that have changed, ie. are not in sync,
     * or false if no attributes have changed.
     */
    changed() {
        let diff = _.reduce(this.$reference, (result, value, key) => {
            return _.isEqual(value, this.$attributes[key])
                ? result
                : result.concat(key);
        }, []);

        return _.isEmpty(diff) ? false : diff;
    }

    /**
     * Returns the property that should be used to identify this model. Can be
     * undefined or null if the model doesn't have an identifier.
     */
    getIdentifier() {
        return "id";
    }

    /**
     * Returns an object of parameters to use when building the 'fetch' URL
     */
    getIdentityParameters() {
        let parameters = {};
        let identifier = this.getIdentifier();

        if (identifier) {
            parameters[identifier] = this.get(identifier);
        }

        return parameters;
    }

    /**
     * @returns {boolean}
     */
    isNew() {
        return ! this.get(this.getIdentifier());
    }

    /**
     * Returns the parameters that should be used when building the fetch route.
     */
    getFetchParameters() {
        return this.getIdentityParameters();
    }

    /**
     * Returns the parameters that should be used when building the save route.
     */
    getSaveParameters() {
        return {};
    }

    /**
     * Returns the parameters that should be used when building any route.
     */
    getDefaultParameters() {
        return {};
    }

    /**
     * Fetches this model's data from the server.
     *
     * Usage:
     *
     * model.fetch({
     *     success: () => {
     *         // Handle success here
     *     },
     *     failure: () => {
     *         // Handle failure here
     *     },
     *     always: () => {
     *         // Handle always here
     *     }
     * })
     *
     * OR
     *
     * model.fetch(() => {
     *     // Handle success here
     * })
     */
    fetch(handlers = {}) {

        // Abort if any of the fetch event listeners returned false.
        if ( ! this.emit("fetch")) {
            return;
        }

        // Parse the success and failure callbacks.
        this.parseResponseHandlers(handlers);

        // Because we're fetching new data, we can assume that this model is
        // now loading. This allows the template to indicate a loading state.
        this.loading   = true;
        this.fatal     = false;

        // Build the URL that we'll use to fetch the model data.
        let route = this.getRoute("fetch", this.getFetchParameters());

        // Make an asynchronous POST request.
        return http.get(route).then((response) => {
            let attributes = response.data;

            // Successful fetch request, data received.
            this.assign(attributes);
            this.emit("fetch.success", {attributes});
            handlers.success();

        }).catch((error) => {

            // The request failed to fetch the data.
            this.fatal = true;
            this.emit("fetch.fail");
            handlers.failure(error);

        }).finally(() => {

            // Even though the request may have failed, the model is not loading
            // anymore. `fatal` should be used to show an error state.
            this.loading = false;
            this.emit("fetch.always");
            handlers.always();
        });
    }

    /**
     * Returns the data to use for saving.
     */
    getSaveData() {
        if (typeof this.$attributes.xero_files === "undefined" || this.$attributes.xero_files.length === 0 || this.$attributes.id > 0) {
            return this.$attributes;
        }
    }

    /**
     * Sets a given object of errors on this model.
     */
    setErrors(errors) {
        this.$errors = errors || {};
    }

    /**
     * Return an array of errors for a particular model property
     */
    getErrors(prop) {
        let errors = this.$errors[prop] || [];

        if (!Array.isArray(errors)) {
            errors = [errors];
        }

        return errors;
    }

    /**
     * Return an error string, or undefined if none
     */
    getError(prop) {
        return this.getErrors(prop)[0];
    }

    /**
     * Saves this model on the server and syncs the data it receives. If
     * the model was created (not updated), it'll be added to all registered
     * collections as well.
     *
     * Usage:
     *
     * model.save({
     *     success: () => {
     *         // Handle success here
     *     },
     *     failure: () => {
     *         // Handle failure here
     *     },
     *     always: () => {
     *         // Handle always here
     *     }
     * })
     *
     * OR
     *
     * model.save(() => {
     *     // Handle success here
     * }, payload = null)
     */
    save(handlers = {}, payload = null) {

        // Don't save if we're already busy saving this model.
        // This prevents things like accidental double clicks.
        if (this.saving) {
            return;
        }

        // Abort if any of the save event listeners returned false.
        if ( ! this.emit("save")) {
            return;
        }

        // I did this once and wondered why models were trying to save.
        if (_.isString(handlers)) {
            throw "Did you mean to call \"saved\" instead?";
        }

        // Parse the success and failure callbacks.
        this.parseResponseHandlers(handlers);

        let url  = this.getRoute("save", this.getSaveParameters());
        let data = payload ? payload : this.getSaveData();

        // Clear errors.
        this.$errors = {};

        // Start saving asynchronously, ie. non-blocking
        this.saving = true;

        return http.post(url, data).then((response) => {
            let attributes = response.data;

            // Determine if the model was created (not updated).
            // Either an explicit 201 CREATED or a new ID.
            let created = response.status === 201 || _.get(attributes, "id") && ! this.get("id");
            this.onSave(attributes, created);

            // Emit either a `create` or `update` event, and always a `save.success` event.
            let event = created ? "create" : "update";
            this.emit(event);
            this.emit("save.success");

            handlers.success(response);

        }).catch((error) => {
            let response = error.response;

            // Validation error, assume errors in JSON response.
            if (response.status === 422) {
                this.setErrors(response.data);
            }

            // Show error message when a request failed in a way that wasn't a
            // validation error, but still a user-error.
            if (response.status === 400) {
                notify.error(response.data);
            }

            // Show error message when a request failed in a way that wasn't a
            // validation error, but still a user-error.
            if (response.status === 500) {
                notify.error("An unexpected error has occurred.");
            }
            this.emit("save.fail", {errors: this.$errors});
            handlers.failure(error);

        }).finally(() => {

            // Even though the request may have failed, the model is not saving
            // anymore. The saving may have failed though.
            this.saving = false;
            this.emit("save.always");
            handlers.always();
        });
    }

    /**
     * Called when this model was deleted successfully.
     */
    onDelete() {
        this.clear();
        this.removeFromAllCollections();
    }

    /**
     * Deletes this model on the server and removes it from all registered
     * collections.
     *
     * Usage:
     *
     * model.delete({
     *     success: () => {
     *         // Handle success here
     *     },
     *     failure: () => {
     *         // Handle failure here
     *     },
     *     always: () => {
     *         // Handle always here
     *     }
     * })
     *
     * OR
     *
     * model.delete(() => {
     *     // Handle success here
     * })
     */
    delete(handlers = {}) {

        // Abort if any of the delete event listeners returned false.
        if ( ! this.emit("delete")) {
            return;
        }

        // Don't save if we're already busy saving this model.
        // This prevents things like accidental double clicks.
        if (this.deleting) {
            return;
        }

        // Parse the success and failure callbacks.
        this.parseResponseHandlers(handlers);

        this.deleting  = true;

        let url = this.getRoute("delete", this.getIdentityParameters());
        // Make an asynchronous DELETE request to delete the model.
        return http.delete(url)
            .then((response) => {
                this.onDelete();
                this.emit("delete.success");
                handlers.success();

            }).catch((error) => {
                this.emit("delete.fail");
                handlers.failure(error);

            }).finally(() => {

                // Even though the request may have failed, the model is not being
                // deleted anymore. The deletion may have failed though.
                this.deleting = false;
                this.emit("delete.always");
                handlers.always();
            });
    }
}

/**
 * Initialise a static model ID.
 */
Model.auto_id = 0;

export default Model;
